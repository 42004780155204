<template>
    <div>
        <van-row class="item-card" v-for="(item,index) in data" v-bind:key="index">
            <van-col offset="1" span="23">
                <van-row class="title van-ellipsis" @click="cardClick(item)">
                    <van-col class="van-ellipsis" span="20">{{ item.customerName }}</van-col>
                    <van-col span="4" v-if="item.status == '00'" class="is-read">新增</van-col>
                    <van-col span="4" v-if="item.status == '01'" class="is-read">延续</van-col>
                    <van-col span="4" v-if="item.status == '02'" class="is-read" style="color: #5CA4F4">审批中</van-col>
                    <van-col span="4" v-if="item.status == '03'" class="is-read" style="color: #0bbd87">已解决</van-col>
                </van-row>
                <van-row v-if="item.problerms" class="info">
                    问题：{{item.problerms}}
                </van-row>
                <van-row v-if="item.createdPeople" class="info">
                    推送人：<span class="push-person">{{item.createdPeople}}</span>
                </van-row>
                <van-row v-if="item.creationTime" class="info">
                    提出日期：{{item.creationTime}}
                </van-row>
<!--                <van-row v-if="item.scheme" class="info content">-->
<!--                  <van-col span="21">-->
<!--                    解决方案：{{item.scheme}}-->
<!--                  </van-col>-->
<!--                </van-row>-->
            </van-col>
        </van-row>
    </div>
</template>

<script>
    export default {
        name: "ProblermCard",
        props: {
            data: {type: Array}
        },
        data() {
            return {}
        },
        methods: {
          cardClick(item) {
            console.log(item)
            this.$emit('cardClickCallBack', item);
          },
        },
        watch: {}
    }
</script>

<style scoped>
    .push-person {
        color: #007fff !important;
    }

    .item-card {
        background: #fff;
        padding: 5px 0;
        margin-top: 1px;
        text-align: left;
    }

    .title {
        font-size: 16px;
        color: #007fff;
        margin-top: 10px;
    }

    .info {
        padding: 5px 0;
        font-size: 10px;
        color: #999999;
    }

    .content{
      font-size: 10px;
    }

    .is-read {
      font-size: 18px;
      font-weight: bolder;
      margin-top: 5px;
      color: red;
    }
</style>
