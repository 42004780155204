<template>
  <div class="remain-problerms">
    <van-row style="background-color: white">
      <van-col span="24">
        <van-col span="5" style="margin-top: 20px;padding-left: 10px">
          <i @click="showGrpFzr()" :class="`el-icon-${isAll ? 'collection-tag' : 'collection-tag'}`"
             :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '13px'}">筛选</i>
          <i @click="showGrpFzr()" :class="`el-icon-${!isAll ? 'arrow-down' : 'arrow-up'}`"
             :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '15px'}"></i>
        </van-col>
        <van-search v-model="selectCustomerVal" :focus="theFocus" custom-class="inp" placeholder="请输入客户名称"
                    show-action
                    @cancel="onCancel()" @search="init()"/>
      </van-col>
    </van-row>
    <div v-if="this.list<=0">
      <van-empty image="error" description="没有数据"/>
    </div>
    <div v-else>
      <problerm-card :style="{marginBottom: index==list.length-1 ? '45px' : '0px'}"
                     v-for="(item,index) in list" v-bind:key="item.crmCustomerid" :data="item" :commonIndex="index"
                     @cardClickCallBack="cardClickCallBack"></problerm-card>
    </div>
    <fzr-card :data="this.columns" ref="grpFzrCard" @group="grp(arguments)"></fzr-card>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import ProblermCard from "@/components/Card/ProblermCard";
import {Toast} from "vant";
import FzrCard from "@/components/Card/FzrCard";

export default {
  name: "RemainProblerms",
  components: {ProblermCard, CrmTabBar, FzrCard},
  data() {
    return {
      list: [],
      selectCustomerVal: '',
      theFocus: false,
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      //上拉标识
      loading: false,
      //上拉加载完成
      finished: false,
      fzr: '',
      isAll: false,
      //组办下拉列表数据源
      columns: [
        {
          values: '',
          id: 'R00311',
          className: 'column1',
          text: '燕钢项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '17039',
              className: 'column1',
              text: '李鹏飞',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '12005',
              className: 'column1',
              text: '李阳',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '13117',
              className: 'column1',
              text: '王立永',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            },
          ]
        },
        {
          values: '',
          id: 'R00258',
          className: 'column2',
          defaultIndex: 0,
          text: '散-总经办',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '05001',
              className: 'column2',
              text: '赵永鹏',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '04008',
              className: 'column2',
              text: '张鸿凯',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00030',
          className: 'column3',
          defaultIndex: 0,
          text: '散-现场组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '12066',
              className: 'column3',
              text: '张强',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00318',
          className: 'column4',
          defaultIndex: 0,
          text: '钢铁华南项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '21196',
              className: 'column4',
              text: '余蜀东',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00202',
          className: 'column5',
          defaultIndex: 0,
          text: '钢铁东北组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '18198',
              className: 'column5',
              text: '王泽民',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '17032',
              className: 'column5',
              text: '马乾博',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '16166',
              className: 'column5',
              text: '孙男',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '13006',
              className: 'column5',
              text: '迟海祥',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '21014',
              className: 'column5',
              text: '陈东宝',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '12007',
              className: 'column5',
              text: '马兆磊',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '18061',
              className: 'column5',
              text: '白也',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00301',
          className: 'column6',
          defaultIndex: 0,
          text: '钢煤项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '17223',
              className: 'column6',
              text: '高龙',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00302',
          className: 'column7',
          defaultIndex: 0,
          text: '电煤项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '16086',
              className: 'column7',
              text: '王帅',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00080',
          className: 'column8',
          defaultIndex: 0,
          text: '船管租船组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '16034',
              className: 'column8',
              text: '刘小恺',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00307',
          className: 'column9',
          defaultIndex: 0,
          text: '长协项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '13072',
              className: 'column9',
              text: '张航',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '18215',
              className: 'column9',
              text: '翟成基',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00170',
          className: 'column10',
          defaultIndex: 0,
          text: '(散)粮食组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '18005',
              className: 'column10',
              text: '许小庆',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '14018',
              className: 'column10',
              text: '王杨',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
      ],
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight
    this.init();
  },
  watch: {
    //监听客户名头
    'selectCustomerVal': function () {
      this.init();
    },
  },
  methods: {
    init() {
      this.list = [];
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.$ajax.post('/api/ajax/problerm/getProblermsListByCustomer.json',
          {
            customerName: this.selectCustomerVal,
            curYear: '',
            curQuarter: '',
            curMonth: '',
            custom: '',
            createdPeople: this.fzr == '' ? this.$store.getters.userId : this.fzr
          }).then(res => {
        if (res.status == 200) {
          if (res.data.length > 0) {
            this.list.push(res.data)
            console.log(this.list)
            Toast.success('加载成功');
          } else {
            Toast.success('暂无数据');
          }
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })
    },
    // 下拉刷新
    onRefresh() {
      // setTimeout(() => {
      //     this.list = []
      //     for (let i = 0; i < 10; i++) {
      //         let data = {
      //             id: Math.random(),
      //             title: '哈尔滨拜访',
      //             date: '2020-12-12',
      //             user: '李阳',
      //             status:'未完成',
      //             to: 'ProjectProgressDetail',
      //         }
      //         this.list.push(data);
      //     }
      //
      //     // 刷新状态结束
      //     this.isLoading = false;
      //     this.finished = false
      // }, 1000);
    },
    // 上拉加载
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //     for (let i = 0; i < 10; i++) {
      //         let data = {
      //             id: Math.random(),
      //             title: '哈尔滨拜访',
      //             date: '2020-12-12',
      //             user: '李阳',
      //             status:'已完成',
      //             to: 'ProjectProgressDetail',
      //         }
      //         this.list.push(data);
      //     }
      //
      // 加载状态结束
      this.loading = false;
      //
      //     // 数据全部加载完成
      //     if (this.list.length >= 40) {
      //         this.finished = true;
      //     }
      // }, 1000);
    },
    onCancel() {
      this.selectCustomerVal = ''
    },
    cardClickCallBack(item) {
      console.log('customer', item)
      this.$router.push({path: "/RemainProblermsDetail/" + item.titleId + "/" + item.crmCustomerid});
    },
    showGrpFzr() {
      this.$refs.grpFzrCard.showPopup = true
      this.$refs.grpFzrCard.pickGrp = 'null'
      this.$refs.grpFzrCard.pickcFzr = 'null'
      this.showPopup = true
    },
    grp(msg) {
      // data为子页面的传过来的数据
      if (msg[1] != 'null') {
        console.log(msg[1])
        this.fzr = msg[1]
      }else{
        this.fzr = ''
      }
      this.init()
    },
  }
}
</script>

<style scoped>
.remain-problerms {
  background-color: #FFFFFF;
}
</style>
<style>
.remain-problerms .van-tab {
  color: #FFF7FD;
  font-weight: 500;
  background-color: #0084F8;
}
</style>
